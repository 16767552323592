export default {
  data() {
    return {
      smallDevicesMatches: false,
    }
  },
  methods: {
    resizeEventHandler(e) {
      this.smallDevicesMatches = window.matchMedia('(max-width: 768px)').matches
    },
  },
  mounted() {
    this.resizeEventHandler()
    window.addEventListener('resize', this.resizeEventHandler)
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.resizeEventHandler)
  },
}
