<template>
  <StaticPagePug>
    <div class="form-wrapper">
        <ChangeAuthenticatorComponent
          :action="usedComponent?.factorAuth"
          :showCancel="false"
          @confirm="confirmAuthenticator"
          @reauthenticate="requireReauthenticate"
        />
      </div>
      </StaticPagePug>
</template>

<script>
import ChangeAuthenticatorComponent from '@/components/security/ChangeAuthenticatorComponent.vue';
import { mapOperation, mapVariable } from '@/components/security/Security.js';
import SocialMedia from '@/components/SocialMedia';
import router from '@/router';
import helper from '@/util/signinHelper.js';
import StaticPagePug from '@/components/template/staticPage/StaticPagePug';

export default {
  name: 'AddAuthentication',
  components: { ChangeAuthenticatorComponent, SocialMedia, StaticPagePug },
  mixins: [],
  data() {
    return {
      usedComponent: null
    };
  },
  mounted() {
    this.usedComponent = mapOperation('enable-auth-2fa');
    if (!this.usedComponent) {
      router.push('/login');
    }
    setTimeout(function () {
      $('.default-from-box').css('animation', 'none').css('display', 'none');
      $('.from-box').css('display', 'block');
    }, 1);
  },
  methods: {
    confirmAuthenticator() {
      const lackAuthValues = this.$store.state.common.checkedAuthStatus.map(item => {
        return {
          ...item,
          verified: true,
          authenticated: item.type == 'totp' ? true : item?.authenticated
        };
      });

      this.$store.commit('common/setCheckedAuthStatus', lackAuthValues);

      if (lackAuthValues.every(item => item.authenticated)) {
        this.$store.state.common.loginCredentials.method == 'register'
          ? helper.register(this.$store.state.common.loginCredentials?.request?.a)
          : this.$store.state.common.loginCredentials.method == 'signInMobile'
          ? helper.signInMobile(
              this.$store.state.common.loginCredentials?.request?.country_code,
              this.$store.state.common.loginCredentials?.request?.mobile_login,
              this.$store.state.common.loginCredentials?.request?.password_login
            )
          : helper.signIn(
              this.$store.state.common.loginCredentials?.request?.userName_login,
              this.$store.state.common.loginCredentials?.request?.password_login
            );
      } else {
        const tempObj = lackAuthValues.find(item => item.path != this.$router.currentRoute.path && !item.authenticated);

        if (tempObj && tempObj?.path) this.$router.push(tempObj?.path);
      }
    },
    requireReauthenticate() {
      this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss" scoped>
.form-wrapper {
    padding: 40px 40px;
    background: white;
    width: auto !important;
    max-width: 757px;
    border-radius: 16px !important;
    overflow-y: scroll;
    // max-height: 800px;
    @include rtl-sass-value(left, calc(100vw - 757px - 15.625%) !important, unset) ;
    @include rtl-sass-value(right, unset, calc(100vw - 757px - 15.625%) !important);
}

// @media (max-width: 550px) {
//     .form-wrapper {
//       transform: translate(0, -30%) !important;
//     }
//   }
</style>
